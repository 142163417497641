/** TODO:
 * Remove trends and sessions
 * Look through the usage of the functions and make their purpose more clear
 *    - Split the functions to have a specific purpose
 * Why do we need the PresentationRoute interface?
 * Do we need all the ifs in buildPresentationPath?
 */

import { MentiError } from '@mentimeter/errors/sentry';

const validRoutes = [
  'edit',
  'embed',
  'follower',
  'integrated',
  'invite',
  'mentimote',
  'old-powerpoint-addin',
  'participant',
  'present',
  'present-preview',
  'results',
  'screenshot',
  'template',
  'view',
] as const;

type ExtractArrayElementType<T extends readonly any[]> =
  T extends readonly (infer U)[] ? U : never;

export type PresentationMode = ExtractArrayElementType<typeof validRoutes>;

export interface PresentationRoute {
  seriesId: string;
  questionId?: string | undefined | null;
  mode: PresentationMode;
  session?: 'trends' | number | null;
  currentSearchParams?: URLSearchParams | undefined;
  source?: string | undefined;
}

export function buildPresentationPath(route: PresentationRoute) {
  if (route.mode === 'view') {
    const searchParams = new URLSearchParams(
      route.currentSearchParams || undefined,
    );
    if (route.questionId) {
      searchParams.set('question', route.questionId);
    }
    const query = searchParams.toString();
    return `/app/presentation/${route.seriesId}/view${query.length > 0 ? `?${query}` : ''}`;
  }

  if (route.mode === 'results') {
    return `/app/presentation/${route.seriesId}/results`;
  }

  if (route.mode === 'follower') {
    return `/app/presentation/${route.seriesId}/follower`;
  }

  if (route.mode === 'screenshot') {
    return `/app/presentation/${route.seriesId}/screenshot/view?question=${route.questionId}`;
  }

  if (route.mode === 'edit') {
    const searchParams = new URLSearchParams(
      route.currentSearchParams || undefined,
    );

    if (route.questionId && route.questionId !== '0') {
      searchParams.set('question', route.questionId);
    }

    if (route.source) {
      searchParams.set('source', route.source);
    }

    const query = searchParams.toString();

    return `/app/presentation/${route.seriesId}/edit${query.length > 0 ? `?${query}` : ''}`;
  }

  if (route.mode === 'embed') {
    const searchParams = new URLSearchParams(
      route.currentSearchParams || undefined,
    );

    if (route.session) {
      searchParams.set('session', String(route.session));
    }
    if (route.questionId) {
      searchParams.set('question', route.questionId);
    }

    const query = searchParams.toString();

    return `/app/presentation/${route.seriesId}/embed${query.length > 0 ? `?${query}` : ''}`;
  }

  if (route.mode === 'present') {
    const searchParams = new URLSearchParams();

    if (route.questionId) {
      searchParams.set('question', route.questionId);
    }

    if (isSessionValid(route.session)) {
      searchParams.set('session', String(route.session));
    }

    const query = searchParams.toString();

    return `/app/presentation/${route.seriesId}/present${query.length > 0 ? `?${query}` : ''}`;
  }

  if (route.mode === 'mentimote') {
    return `/app/presentation/${route.seriesId}/mentimote`;
  }

  if (route.mode === 'template') {
    const searchParams = new URLSearchParams();

    if (route.questionId) {
      searchParams.set('question', route.questionId);
    }
    if (isSessionValid(route.session)) {
      searchParams.set('session', String(route.session));
    }

    const query = searchParams.toString();

    return `/app/presentation/${route.seriesId}/template${query.length > 0 ? `?${query}` : ''}`;
  }

  if (route.mode === 'integrated') {
    const searchParams = new URLSearchParams(
      route.currentSearchParams || undefined,
    );

    if (route.questionId) {
      searchParams.set('question', route.questionId);
    }
    const query = searchParams.toString();

    return `/app/presentation/${route.seriesId}/integrated${query.length > 0 ? `?${query}` : ''}`;
  }

  if (route.mode === 'old-powerpoint-addin') {
    const searchParams = new URLSearchParams();
    if (route.questionId) {
      searchParams.set('question', route.questionId);
    }
    const query = searchParams.toString();
    return `/app/presentation/${route.seriesId}/old-powerpoint-addin${query.length > 0 ? `?${query}` : ''}`;
  }

  if (route.mode === 'present-preview') {
    const searchParams = new URLSearchParams();
    if (route.questionId) {
      searchParams.set('question', route.questionId);
    }
    const query = searchParams.toString();
    return `/app/presentation/${route.seriesId}/present-preview${query.length > 0 ? `?${query}` : ''}`;
  }

  if (route.mode === 'invite') {
    const searchParams = new URLSearchParams();
    if (route.questionId) {
      searchParams.set('question', route.questionId);
    }
    const query = searchParams.toString();
    return `/app/presentation/${route.seriesId}/invite${query.length > 0 ? `?${query}` : ''}`;
  }
  if (route.mode === 'participant') {
    const searchParams = new URLSearchParams();
    if (route.questionId) {
      searchParams.set('question', route.questionId);
    }
    const query = searchParams.toString();
    return `/app/presentation/${route.seriesId}/participant${query.length > 0 ? `?${query}` : ''}`;
  }

  throw new MentiError(`Unknown mode: ${route.mode}`, {
    feature: 'presentation-url',
  });
}

/**
 * Parse url fragments to info about presentation
 * @param pathname Pathname **excluding** the search parameters
 * @param searchParams search parameters, or null
 */
export function parsePresentationPathAndSearchParams(
  pathname: string,
  searchParams: URLSearchParams | null,
): PresentationRoute {
  const pathParts = pathname.replace(/\/$/, '').split('/');

  const [, , , seriesId, view] = pathParts;
  if (!validRoutes.includes(view as PresentationMode)) {
    throw new MentiError(
      `Invalid presentation URL: view: ${view} for pathname: ${pathname}`,
      {
        feature: 'presentation-url',
      },
    );
  }

  const questionId = searchParams?.get('question');
  const session = searchParams?.get('session');

  const route: PresentationRoute = {
    seriesId: seriesId || '',
    mode: view as PresentationMode,
  };

  if (questionId) route.questionId = questionId;

  if (session) {
    route.session = isNaN(Number(session))
      ? ((session as PresentationRoute['session']) ?? null)
      : Number(session);
  }
  return route;
}

export function parsePresentationUrl(href: string): PresentationRoute {
  const url = new URL(href);
  return parsePresentationPathAndSearchParams(url.pathname, url.searchParams);
}

export function parsePresentationSession(
  sessionParam: string | undefined | null,
) {
  if (!sessionParam) return null;
  if (sessionParam === 'trends') {
    return 'trends';
  }
  const parsedSessionNumber = Number(sessionParam);
  if (isSessionValid(parsedSessionNumber)) return parsedSessionNumber;
  return null;
}

function isSessionValid(session: PresentationRoute['session']) {
  return session === 'trends' || Number.isInteger(session);
}
