'use client';
import { TrackingMethods } from '@mentimeter/google-tracking';
import { core } from '@mentimeter/http-clients';
import { usePathname } from '@mentimeter/next-navigation';
import type { ButtonT } from '@mentimeter/ragnar-ui';
import { Button } from '@mentimeter/ragnar-ui';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_LOCALE } from 'src/constants.cjs';
import { useAppState } from '../appState';
import type { RenderPropsParamT } from '../utils/components/AuthModalClicker';
import AuthModalClicker from '../utils/components/AuthModalClicker';
import { sleep } from '../utils/sleep';

const ERROR_TIMEOUT = 5000;

const defaultTags = ['Example'];

export type CloneButtonT = ButtonT & {
  name: string;
  seriesId: string;
  trackProperties?: { Context?: string };
};

const TEMPLATES_REGION = 'eu';
const getContextFromPath = (url: string) =>
  url.split('/').filter(Boolean)[0] || '/';

const CloneButton = ({
  name,
  seriesId,
  trackProperties = {},
  variant = 'primary',
  ...props
}: CloneButtonT) => {
  const { t, i18n } = useTranslation('common');
  const pathname = usePathname();
  const { language: locale } = i18n;
  const { user } = useAppState();
  const context = getContextFromPath(pathname);
  const continueUrl = `${locale !== DEFAULT_LOCALE ? `/${locale}` : ''}${pathname}`;
  const [status, setStatus] = React.useState<ButtonT['state'] | undefined>(
    undefined,
  );
  const [clonedSeriesUrl, setClonedSeriesUrl] = React.useState<string | null>(
    null,
  );

  const sleepAndSetIdleState = React.useCallback(
    async (duration: number = ERROR_TIMEOUT) => {
      await sleep(duration);
      setStatus(undefined);
    },
    [],
  );
  const clone = React.useCallback(async () => {
    if (clonedSeriesUrl) {
      return window.location.assign(clonedSeriesUrl);
    }

    setStatus('loading');

    try {
      const clonedSeries = await core().duplicate.series({
        copy: seriesId,
        track_context: context,
        region: TEMPLATES_REGION,
        tags: defaultTags,
      });

      setClonedSeriesUrl(
        `/app/presentation/${clonedSeries.data.id}/edit?question=${clonedSeries.data.questions[0]!.id}`,
      );
      setStatus(undefined);
    } catch (error) {
      setStatus('error');
      sleepAndSetIdleState(ERROR_TIMEOUT);
    }
  }, [clonedSeriesUrl, context, seriesId, sleepAndSetIdleState]);
  const clickHandler = React.useCallback(
    ({ onClick }: RenderPropsParamT) => {
      if (user) {
        return clone;
      }

      return () => {
        TrackingMethods.trackAddTemplate(name);

        return onClick();
      };
    },
    [clone, name, user],
  );

  return (
    <AuthModalClicker
      showSignup
      continueUrl={continueUrl}
      referral={context || ''}
      render={(args) => (
        <Button
          {...props}
          data-testid="clone-button"
          aria-label="Clone template"
          state={status}
          onClick={clickHandler(args)}
          variant={clonedSeriesUrl ? 'outline' : variant}
          extend={() => ({ minWidth: '129px' })}
        >
          {clonedSeriesUrl
            ? t('common:templates.open')
            : t('common:templates.add_template')}
        </Button>
      )}
    />
  );
};

export default CloneButton;
