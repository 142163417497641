// Use host from env vars on the server, but use location.origin when running inside browsers.
// This allows us to generate URLs for Plug'n'Play, without having to have unique env vars for
// every environment.
//
// With this setup we risk getting DOM mismatches when rehydrating React on the client, but only
// for PnP and when bypassing the proxy. For production scenarios CANONICAL_HOST and location.origin
// should be the same. Also, it is not a problem at all until we SSR on www.
const getCanonicalHost = () => {
  const CANONICAL_HOST = process.env.NEXT_PUBLIC_CANONICAL_HOST;
  const HEROKU_APP_NAME = process.env.NEXT_PUBLIC_HEROKU_APP_NAME;
  if (CANONICAL_HOST) {
    return CANONICAL_HOST;
  }
  if (HEROKU_APP_NAME) {
    return `https://${process.env.NEXT_PUBLIC_HEROKU_APP_NAME}.herokuapp.com`;
  }
  return '';
};

export const HOST =
  typeof window === 'undefined' ? getCanonicalHost() : window.location.origin;

/**
 * Build a full mentimeter URL from path input string.
 * @param path string of the path starting with a '/'
 * @returns the path param preceded by the HOST (CANONICAL_HOST/window.location.origin).
 */
export function fullMentimeterUrl(path: string) {
  if (!path.startsWith('/'))
    throw new Error('Invalid path passed to fullMentimeterUrl()');

  return `${HOST}${path}`;
}
